import * as React from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'


export interface Airport {
    id: number
    city: string
    iata_code: string
    is_eu: boolean
}


export interface OnChangeFunc {
    (value: Airport[]): void
}


interface AirportAutocompleteProps {
    id?: string
    name?: string
    placeholder?: string
    onChange: OnChangeFunc
    onBlur?: Function | null
    defaultValue?: string
    selected?: Airport
}


export default
class AirportAutocomplete extends React.Component<AirportAutocompleteProps> {
    state = {
        requestCount: 0,
        airports: [],
        defaultValue: null,
    }

    componentDidMount () {
        if (this.props.defaultValue) {
            this.loadDefault()
        }
    }

    async loadDefault () {
        let data = await this.loadAirports(this.props.defaultValue, true)
        this.setState({defaultValue: data})
        this.props.onChange(data)
    }

    getLabel = (item) => {
        if (item.name.indexOf(item.city) != -1) {
            return `${item.name} (${item.iata_code})`
        } else {
            return `${item.city} ${item.name} (${item.iata_code})`
        }
    }

    onSearch = async (query) => {
        this.loadAirports(query)
    }

    async loadAirports (query, searchByCode = false) {
        this.setState({
            requestCount: this.state.requestCount + 1
        })

        let url = `/api/airports?search=${query}&random=${Math.random()}`
        if (searchByCode) {
            url = `/api/airports?iata_code=${query}&random=${Math.random()}`
        }
        let result = await (await fetch(url)).json()

        this.setState({requestCount: this.state.requestCount - 1, airports: result})

        return result
    }

    renderMenuItemChildren (option, props, index) {
        return <React.Fragment key={option.iata_code}>
            <div>{option.city} ({option.iata_code})</div>
            <small style={{paddingLeft: '10px'}}>{option.name}</small>
        </React.Fragment>
    }

    render () {
        if (this.props.defaultValue && !this.state.defaultValue) {
            return null
        }

        return <AsyncTypeahead
            id={this.props.id}
            name={this.props.name}
            options={this.state.airports}
            labelKey={this.getLabel}
            onSearch={this.onSearch}
            isLoading={this.state.requestCount > 0}
            minLength={2}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            placeholder={this.props.placeholder}
            defaultSelected={this.state.defaultValue || []}
            selected={this.props.selected ? [this.props.selected] : null}
            renderMenuItemChildren={this.renderMenuItemChildren}
        />
    }
}
