import {action} from 'mobx'
import {observer} from 'mobx-react'
import autobind from 'autobind-decorator'
import * as React from 'react'
import * as qs  from 'qs'
import { t } from 'ttag'
import {Form, FormGroup, Label, Input, Button, Alert} from 'reactstrap'
import {getStateInstance, RefFieldState} from './state'
import { FieldState } from 'formstate'
import AirportAutocomplete, {Airport} from '../Forms/AirportAutocomplete'
import {FieldFeedBack} from '../Forms/FieldFeedBack'
import { navigate } from '../../util/navigate'
import {getLanguageCode} from '../../locales'
import { RadioButtonOption, RadioButtons } from '../RadioButtons/RadioButtons'

enum ConnectingAirportCount {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
}

const CONNECTING_AIRPORT_COUNT_CHOICES: RadioButtonOption[] = [
    { value: ConnectingAirportCount.ONE },
    { value: ConnectingAirportCount.TWO },
    { value: ConnectingAirportCount.THREE },
    { value: ConnectingAirportCount.FOUR },
]

interface Props {
    defaultDepartureAirport: string
    defaultArrivalAirport: string
    location: Location
}


function onAirportFieldChange (field: FieldState<Airport>, selected: Airport[]) {
    const airport = selected.length > 0 ? selected[0] : null
    field.onChange(airport)
    getStateInstance().formState.route.validate()
}


@observer
export class TripSummaryForm extends React.Component<Props> {
    state = {
        hasUrlHash: false
    }

    componentDidMount(): void {
        let urlParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        if (urlParams['direct_to_form']) {
            this.setState({ hasUrlHash: !this.state.hasUrlHash })
        }
    }   

    render () {
        let pageState = getStateInstance()
        let formState = pageState.formState.route

        let connAirports = pageState.formState.route.$.slice()
        let depAirport = connAirports.shift()
        let destAirport = connAirports.pop()

        return <Form onSubmit={this.onSubmit}>
            <h1>{t`Trip summary`}</h1>

            {this.state.hasUrlHash && <p>
                {t`
                    Could you be entitled to flight compensation? Let’s check your flight
                `}
            </p>}

            <FormGroup className="mt-3">
                <a ref={depAirport.positionRef as any}/>
                <Label for="departure-airport">{t`Departure airport`}</Label>
                <AirportAutocomplete
                    id="departure-airport-autocomplete"
                    name="departure-airport"
                    placeholder={t`E.g. Amsterdam (AMS)`}
                    defaultValue={this.props.defaultDepartureAirport}
                    selected={depAirport.value}
                    onChange={(sel) => onAirportFieldChange(depAirport, sel)}
                    onBlur={depAirport.validate}
                />
                <FieldFeedBack field={depAirport}/>
            </FormGroup>

            <FormGroup>
                <a ref={destAirport.positionRef as any}/>
                <Label for="destination-airport">{t`Destination airport`}</Label>
                <AirportAutocomplete
                    id="destination-airport-autocomplete"
                    name="destination-airport"
                    placeholder={t`E.g. Amsterdam (AMS)`}
                    defaultValue={this.props.defaultArrivalAirport}
                    selected={destAirport.value}
                    onChange={(sel) => onAirportFieldChange(destAirport, sel)}
                    onBlur={destAirport.validate}
                />
                <FieldFeedBack field={destAirport}/>
            </FormGroup>

            <p className="mt-3">
                {t`Was it a direct flight?`}
            </p>

            <FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="radio"
                            name="is_direct_flight"
                            checked={pageState.isDirectFlight}
                            onChange={action(() => pageState.onIsDirectFlightChange(true))}
                        />
                        {' '} {t`Yes`}
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="radio"
                            name="is_direct_flight"
                            checked={!pageState.isDirectFlight}
                            onChange={action(() => pageState.onIsDirectFlightChange(false))}
                        />
                        {' '} {t`No`}
                    </Label>
                </FormGroup>
            </FormGroup>

            {this.renderConnectingAirports(connAirports)}

            <Alert color="primary">
                {t`No risk. Check your eligibility for compensation`} <b>{t`for free.`}</b>
            </Alert>

            <Button
                disabled={formState.hasError}
                className="submit"
                block
                color="primary"
            >

                {t`Check compensation`}

            </Button>

        </Form>
    }

    @autobind
    async onSubmit (e) {
        e.preventDefault()

        let pageState = getStateInstance()
        let formState = pageState.formState.route

        let res = await formState.validate()

        if (res.hasError) {
            for (let f of formState.$) {
                if (f.hasError) {
                    f.scrollIntoView()
                    break
                }
            }
        } else {
            pageState.initFlights()
            navigate('/claim-form/flight-details', getLanguageCode(this.props.location))
        }
    }

    renderConnectingAirports (connAirports: RefFieldState<Airport>[]) {
        let pageState = getStateInstance()
        let connectingAirportCount = pageState.formState.route.$.slice().length - 2

        if (pageState.isDirectFlight) return

        return <div>
            <p className="mt-3">
                {t`How many stops did you have?`}
            </p>
            <FormGroup>
                <RadioButtons
                    name="connecting_airport_count"
                    options={CONNECTING_AIRPORT_COUNT_CHOICES}
                    onChange={pageState.onConnectingAirportCountChange}
                    value={connectingAirportCount}
                />
            </FormGroup>
            {connAirports.map((airportField, index) => (
                <FormGroup className="mt-3" key={`${index}_${airportField.$?.id}`}>
                    <Label for="departure-airport">
                        {t`Where did you have a stop?`}
                    </Label>

                    <AirportAutocomplete
                        id="departure-airport-autocomplete"
                        name={`connecting-airport-${index}`}
                        placeholder={t`E.g. Amsterdam (AMS)`}
                        selected={airportField.value}
                        onChange={(sel) => onAirportFieldChange(airportField, sel)}
                        onBlur={airportField.validate}
                    />

                    <FieldFeedBack field={airportField}/>
                </FormGroup>
            ))}
        </div>
    }
}
