import * as React from 'react'
import * as qs from 'qs'
import {Container} from 'reactstrap'
import {observer} from 'mobx-react'
import Layout from '../../components/Layout/Layout'
import {TripSummaryForm} from '../../components/ClaimForm/TripSummaryForm'

import './claim-form.scss'
import DebugStepLinks from '../../components/ClaimForm/DebugStepLinks'


interface Query {
    from?: string
    to?: string
}


interface Props {
    location: Location
}


@observer
export default class ClaimFormFlightPage extends React.Component<Props> {
    query: Query = {}

    constructor (props) {
        super(props)

        this.query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    }

    render () {
        return <Layout className="claim-form-page">
            <Container>
                <div className="row mb-5">

                    <div className="offset-md-2 col-md-8">
                        <TripSummaryForm
                            location={this.props.location}
                            defaultDepartureAirport={this.query.from}
                            defaultArrivalAirport={this.query.to}
                        />
                    </div>
                </div>
            </Container>

            <DebugStepLinks/>

        </Layout>
    }
}
